<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="医生团队名称：">
        <el-input
          v-model.trim="formInline.teamName"
          v-check-permission
          placeholder="请输入医生团队名称"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button
          v-if="$checkPermission('DOCTORTEAMADD', $route)"
          type="primary"
          @click="dataOperation(null, 0)"
        >
          新增
        </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="teamList"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />

      <el-table-column prop="teamCode" label="医生团队编码" align="center" />
      <el-table-column prop="teamName" label="医生团队名称" align="center" />

      <el-table-column
        prop="introduce"
        label="医生团队介绍"
        align="center"
        show-overflow-tooltip
      />

      <el-table-column label="医生团队链接" align="center">
        <template slot-scope="scope">
          <a
            :href="`${jumpHost}/h5-medical-consult/famousDoctor?teamCodes=${scope.row.teamCode}&codeList=1`"
            target="_blank"
            rel="noopener noreferrer"
            >{{
              `${jumpHost}/h5-medical-consult/famousDoctor?teamCodes=${scope.row.teamCode}&codeList=1`
            }}</a
          >
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="introduce"
        label="团队负责人"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="mobile"
        label="手机号"
        align="center"
        show-overflow-tooltip
      />
      v <el-table-column
        prop="email"
        label="邮箱"
        align="center"
        show-overflow-tooltip
      /> -->
      <el-table-column label="操作" width="200" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('DOCTORTEAMEDIT', $route)"
            type="primary"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ServiceManageList',
  data() {
    return {
      jumpHost: 'https://member-test.manniuhealth.com',
      loading: false,
      formInline: {
        teamName: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState('doctorTeam', {
      teamList: (state) => state.teamList.data,
      totalNum: (state) => Number(state.teamList.total),
    }),
  },
  mounted() {
    console.log(process.env.VUE_APP_ENV, 'process.env.VUE_APP_ENV');
    if (process.env.VUE_APP_ENV === 'production') {
      this.jumpHost = 'https://member.manniuhealth.com/';
    }
    this.getTeamList();
  },
  methods: {
    ...mapActions('doctorTeam', ['pageQuery', 'edit']),
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getTeamList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getTeamList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        teamName: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getTeamList();
    },
    queryData() {
      this.getTeamList();
    },
    // 分页查询用户套餐列表
    async getTeamList() {
      this.loading = true;
      const params = {
        teamName: this.formInline.teamName,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      await this.pageQuery(params);
      this.pageInfo.total = this.totalNum;
      this.loading = false;
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      this.$router.push({
        path: '/doctorTeam/teamDetail',
        query: {
          teamDetail: item,
          type: Number(type),
        },
      });
    },
  },
};
</script>
<style lang="css">
.el-tooltip__popper {
  max-width: 400px;
}
</style>
<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
